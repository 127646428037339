<template>
  <span @click="handleClick">
    <slot></slot>
  </span>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import api from '@/api/account'
export default {
  name: 'Submit',
  data() {
    return {
      loading: false,
    }
  },
  mounted() {
    this.getIntro()
  },
  activated() {
    this.getIntro()
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions(['getOrgIntro']),
    getIntro() {
      if (this.userInfo.userType === 'LAWYER') {
        api.getAccount('LAWYER').then(res => {
          this.account = res
        })
      }
    },
    handleClick() {
      if (this.userInfo.userType === 'LAWYER') {
        this.handleLawyer()
      } else {
        this.$emit('click')
      }
      // this.$emit('click')
    },
    handleLawyer() {
      if (this.account && this.account.organizationId) {
        this.$emit('click')
      } else {
        this.$message.warning('请先绑定律所')
        setTimeout(() => {
          this.$router.push('/usercenter/profile')
        }, 3000)
      }
    }
  },
}
</script>

<style>
</style>
