<template>
  <div v-loading="loading" class="lawyerDetails">
    <Breadcrumb :data="breData" />

    <Card>
      <template v-slot:header>
        {{ $t('747b48c') }}
        <span style="color: red; font-size: 14px; margin-left: 16px">
          {{ '（' + $t('37bbf16') + '！）' }}
        </span>
      </template>
      <template v-slot:actions>
        <div>
          <Submit @click="apply">
            <el-button
              style="
                height: 32px;
                padding: 8px 22px;
                border-color: rgba(0, 164, 255, 1);
                margin-right: 10px;
              "
            >
              <span
                style="color: rgba(0, 164, 255, 1); font-size: 14px"
              >{{ $t('0d43cb7') }}</span>
            </el-button>
          </Submit>
          <el-button
            style="
              height: 32px;
              padding: 8px 22px;
              border-color: rgba(0, 164, 255, 1);
            "
            @click="consultVisible = true"
          >
            <span style="color: rgba(0, 164, 255, 1); font-size: 14px">
              {{ $t('249393c') }}
            </span>
          </el-button>
        </div>
      </template>

      <ServiceDetailsCard :data="detailsData" :label-data="labelData" />
    </Card>
    <ConsultModal
      v-if="consultVisible"
      :id="detailsData.id"
      :visible="consultVisible"
      type="REQUIREMENTS"
      @close="consultVisible = false"
    />
  </div>
</template>

<script>
import Breadcrumb from '@/components/Breadcrumb'
import ServiceDetailsCard from '@/components/Service/ServiceDetailsCard'
import Card from '@/components/Card'
import ConsultModal from '@/components/ConsultModal'
import Submit from '@/components/PermissionContainer/Submit'

import api from '@/api/service'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'DemandDetails',
  components: {
    Breadcrumb,
    ServiceDetailsCard,
    Card,
    ConsultModal,
    Submit,
  },
  data() {
    return {
      loading: true,
      consultVisible: false,
      row: 3,
      pageInfo: {
        currentPage: 1,
        size: 9,
        total: 1,
      },
      jumpObj: {
        id: 'xx',
        text: '立即报名',
        path: '/content/request/cooperation',
      },
      breData: [
        {
          name: '法律需求',
          path: '/service/demand',
        },
        {
          name: '法律需求详情',
          path: '/service/demand/details',
        },
      ],
      labelData: [
        { label: '需求标题', key: 'title' },
        { label: '需求类型', key: 'type' },
        { label: '涉及区域', key: ['country', 'province'] },
        { label: '需求编码', key: 'code' },
        { label: '需求状态', key: 'urgentStatus' },
        { label: '发布时间', key: 'createTime' },
        { label: '期限要求', key: ['startTime', 'endTime'] },
        { label: '所属区域', key: 'area' },
        { label: '发布单位', key: 'name' },
        { label: '联系人', key: 'contactPerson' },
        { label: '联系电话', key: 'contactPhone' },
        { label: '联系邮箱', key: 'email' },
        { label: '附件', key: ['fileName', 'annex'] },
        { label: '业务类型', key: 'businessType' },
        { label: '需求内容', key: 'content' },
      ],
    }
  },
  watch: {
    detailsData() {
      this.loading = false
    },
  },
  computed: {
    ...mapGetters(['detailsData']),
  },
  created() {
    this.getData()
  },
  methods: {
    ...mapActions(['getLawRequirementsDetails']),
    apply() {
      api.requestApply(this.detailsData.id).then((res) => {
        if (res.code === '000000') {
          this.$router.push({
            path: '/content/request/cooperation',
          })
        } else {
          this.$message.error(res.message)
        }
      })
    },
    getData() {
      this.getLawRequirementsDetails({
        id: this.$route.query.id,
      })
    },
  },
}
</script>
<style scoped lang="less">
@import '~@/styles/variables.less';
.lawyerDetails {
  width: @uni-width;
  min-height: calc(100vh - 624px);
  margin: 0 auto;
  .serviceDetailsCard {
    margin-bottom: 20px;
    background: #ffffff;
    box-shadow: 0px 2px 22px 0px rgba(142, 142, 142, 0.18);
  }
}
</style>
