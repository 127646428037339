<template>
  <el-row class="container">
    <el-col
      v-for="(item, index) of labelData"
      :key="index"
      class="col"
      :span="handleSpan(index, item.key)"
    >
      <div class="label">{{ item.label }}：</div>
      <div
        class="content"
        :class="classObj(data, item, index)"
        :style="styleObj(data, item, index)"
      >
        <a
          v-if="item.key.includes('fileName') && data.annex && data.fileName"
          :href="data[item.key[1]]"
        >{{ handleIData(data, item) | textFilter }}
        </a>
        <span v-else>{{ handleIData(data, item) | textFilter }}</span>
      </div>
    </el-col>
  </el-row>
</template>

<script>
import { urgentStatus, TYPE } from '@/utils/constants'
export default {
  name: 'ServiceDetailsCard',
  components: {},
  props: {
    data: {
      type: Object,
      required: true,
    },
    labelData: {
      type: Array,
      required: true,
    },
    closeColor: {
      type: Boolean,
      default: false,
    },
    defaultSpan: {
      type: Number,
      default: 8,
    },
  },
  data() {
    return {}
  },
  computed: {
    // ...mapGetters(['activeIndex']),
  },
  methods: {
    handleIData(data, item) {
      const keyType = typeof item.key
      let result = data[item.key]
      if (keyType === 'string') {
        result = this.handleItemIsString(data, item)
      } else if (Array.isArray(item.key)) {
        result = this.handleItemIsArray(data, item)
      }
      return result
    },
    handleItemIsString(data, item) {
      let result = data[item.key]
      if (item.key === 'urgentStatus') {
        result = urgentStatus[result]
      } else if (item.key === 'type') {
        result = TYPE[result]
      } else if (item.key === 'serviceType') {
        result = data.type
      }
      // else if (item.key === 'contactPhone') {
      //   result = getToken() ? result : (result.substr(0, 2) + '***' + result.substr(5, result.split('').length))
      // }
      return result
    },
    handleItemIsArray(data, item) {
      if (item.key.includes('startTime')) {
        const startTime = data[item.key[0]]
        const endTime = data[item.key[1]]
        if (startTime && endTime) {
          return `${startTime} ～ ${endTime}`
        }
      } else if (item.key.includes('fileName')) {
        const fileName = data[item.key[0]]
        const annex = data[item.key[1]]
        if (fileName && annex) {
          return fileName
        }
      } else if (item.key.includes('country')) {
        const country = data[item.key[0]]
        let province = data[item.key[1]]
        province = province ? '-' + province : ''
        return country + province
      }
    },
    styleObj(data, item, index) {
      if (!this.closeColor) {
        let color = ''
        const key = item.key
        const val = data[key]
        if (key === 'urgentStatus' && val) {
          color = '#F5222D'
        } else if (key === 'annex' && val) {
          color = '#00A4FF'
        }
        return {
          color,
        }
      }
    },
    classObj(data, item, index) {
      const len = this.labelData.length - 1
      const val = data[item.key]
      const key = item.key
      const result = {
        enclosureIcon: false,
        last: false,
      }
      if (!this.closeColor && key === 'annex' && val) {
        result.enclosureIcon = true
      }
      if (index === len || key === 'contactPerson') {
        result.last = true
      }
      return result
    },
    handleSpan(index, key) {
      key = Array.isArray(key) ? key[0] : key
      let result = this.defaultSpan
      const len = this.labelData.length - 1
      const arr = ['contactPerson', 'businessType', 'fileName', 'annex']
      if (index === 0 || index === len) {
        result = 24
      } else if (arr.includes(key)) {
        result = 24
      }
      return result
    },
  },
}
</script>

<style scoped lang="less">
@import '~@/styles/variables.less';
@import '~@/styles/common.less';
.container {
  padding: 30px 30px 16px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  .col {
    display: flex;
    margin-bottom: 16px;
    line-height: 20px;
  }
  .label {
    white-space: nowrap;
  }
  .content {
    color: #333333;

    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
  }
  .enclosureIcon {
    position: relative;
    left: 25px;
    &::before {
      content: '';
      position: absolute;
      left: -20px;
      width: 14px;
      height: 14px;
      background: url('~@/assets/service/iconPaperclip.png');
      background-size: 14px 14px;
    }
  }
  .last {
    position: relative;
    top: -3px;
    line-height: 22px;
  }
}
</style>
