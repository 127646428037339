<template>
  <el-dialog
    :visible="visible"
    :title="$t('249393c')"
    width="488px"
    class="consult-modal"
    :lock-scroll="true"
    @close="handleClose"
  >
    <el-form
      ref="form"
      label-suffix=":"
      label-width="90px"
      :model="model"
      :rules="rules"
    >
      <el-form-item label="联系电话" required prop="contactPhone">
        <el-input v-model="model.contactPhone" :placeholder="$t('18b2b78')" />
      </el-form-item>
      <el-form-item label="咨询内容" required prop="consultationContent">
        <el-input
          v-model="model.consultationContent"
          type="textarea"
          :rows="5"
          placeholder="请输入咨询内容"
          maxlength="500"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleSubmit">确 定</el-button>
      <el-button @click="handleClose">取 消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import api from "@/api/consult";
import { Message } from "element-ui";
import validation from "@/utils/validation";

export default {
  name: "ConsultModal",
  props: {
    visible: Boolean,
    id: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      model: {
        contactPhone: undefined,
        consultationContent: undefined,
      },
    };
  },
  computed: {
    rules() {
      return {
        contactPhone: [
          { required: true, message: this.$t("18b2b78") },
          { validator: validation.contactNumber },
        ],
        consultationContent: [{ required: true, message: this.$t("4b83f94") }],
      };
    },
  },
  methods: {
    handleClose() {
      this.$emit("close");
    },
    handleSubmit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          // TODO 提交咨询
          api
            .send({
              ...this.model,
              contactId: this.id,
              type: this.type,
            })
            .then((res) => {
              if (res && res.code === "000000") {
                Message({
                  message: "咨询成功",
                  type: "success",
                  duration: 5 * 1000,
                  showClose: true,
                });
                this.$emit("close");
              } else {
                this.$message.error(res.message);
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.consult-modal {
  // background: red;
  /deep/ .el-dialog__header {
    border-bottom: 1px solid #e8e8e8;
    padding: 20px 24px;
  }
}
</style>
